const state = {
  user: null,
  userInfo: null,
}
const getters = {
  isAuthenticated: (state) => !!state.user,
  StateUser: (state) => state.user,
  UserInfo: (state) => state.userInfo,
}
const actions = {
  async LogIn({ commit }, User) {
    // await axios.post('user/login', User)
    await commit('setUser', User)
  },
  async LogOut({ commit }) {
    let user = null
    commit('LogOut', user)
  },
  async saveUser({ commit }, Data) {
    commit('saveUser', Data)
  },
}
const mutations = {
  setUser(state, username) {
    state.user = username
  },
  saveUser(state, data) {
    state.userInfo = data
  },
  LogOut(state) {
    state.user = null
    state.userInfo = null
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
}
