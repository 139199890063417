<template>
  <CSidebar
    position="fixed"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <div class="sidebar-header-logo">
      WIWI CENTER<CImage
        class="sidebar-close-icon"
        :src="icClose"
        v-on:click="closeSidebar"
      />
    </div>
    <!--    <p class="sidebar-header-logo">WIWI CENTER</p>-->

    <div class="sidebar-menu">
      <a class="sidebar-item" href="/subscription"
        ><CImage
          class="sidebar-item-img"
          alt="home"
          :src="icHome"
        />Subscription Plan
      </a>
      <a class="sidebar-item" href="/device">
        <CImage class="sidebar-item-img" alt="home" :src="icSetting" />Device
        Management
      </a>
      <a class="sidebar-item" style="margin-left: 33px" v-on:click="logout"
        >Logout</a
      >
    </div>
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import icSetting from '@/assets/icons/ic-setting.png'
import icHome from '@/assets/icons/ic-home.png'
import icClose from '@/assets/icons/ic_close.png'

export default {
  name: 'AppSidebar',
  data() {
    return {
      visible: true,
    }
  },

  setup() {
    const store = useStore()
    return {
      icHome,
      icSetting,
      icClose,
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
  mounted() {
    this.toggleSidebar()
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('LogOut')
      this.$router.push('/login')
    },
    closeSidebar() {
      this.$store.commit({
        type: 'updateSidebarVisible',
        value: false,
      })
    },
    toggleSidebar() {
      window.addEventListener('resize', () => {
        const visible = window.innerWidth > 767
        this.$store.commit({
          type: 'updateSidebarVisible',
          value: visible,
        })
      })
      window.addEventListener('load', () => {
        const visible = window.innerWidth > 767
        this.$store.commit({
          type: 'updateSidebarVisible',
          value: visible,
        })
      })
    },
  },
}
</script>
