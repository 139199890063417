<template>
  <CHeader position="sticky">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <AppBreadcrumb />
      </CHeaderNav>
      <CHeaderNav class="app-nav">
        <a href="https://anime.wiwicenter.com/" target="_blank">
          <CCardText class="app-nav-text">WiWi Anime</CCardText>
        </a>
        <a href="https://cinema.wiwicenter.com/" target="_blank">
          <CCardText class="app-nav-text">WiWi Cinema</CCardText>
        </a>
      </CHeaderNav>
      <CHeaderNav>
        <div class="display-name">
          <b>{{ account.account_name }}</b
          ><br />
          ID: <b>{{ user.user_id }}</b>
        </div>
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import { logo } from '@/assets/brand/logo'
import avatar from '@/assets/images/logo/anime.png'
import { mapGetters } from 'vuex'
export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
  },
  created: function () {
    this.user = this.$store.getters.UserInfo
    this.account = this.$store.getters.StateUser
  },
  computed: {
    ...mapGetters({ User: 'StateUser' }),
  },
  setup() {
    return {
      avatar: avatar,
      logo,
      user: '',
      account: '',
    }
  },
}
</script>
